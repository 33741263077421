import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults } from "../../admin.state";
import { UserWithPermissions } from "../models/user.interface";
import { UsersPermissionsService } from "../users-permissions.service";
import { CreateUser, LoadRoles, LoadUsers, RemoveUser, ResetSelectedUser, SaveInformation, SaveRoles, SaveUserPermissions, SelectUser } from "./users-permissions.actions";
import { UsersPermissionsStateModel } from "./users-permissions.model";

@State<UsersPermissionsStateModel>({
	name: "userPermissions",
	defaults: {
		...AdminPagesStateDefaults,
		selectedUser: null,
		userLoading: false,
		totalUsers: null,
		lastDeletedId: null,
		roleOptions: []
	}
})
@Injectable()
export class UsersPermissionsState {

	constructor(
		private usersPermissionsService: UsersPermissionsService
	) {}

	@Selector() static users(state: UsersPermissionsStateModel) { return state.items; }
	@Selector() static loading(state: UsersPermissionsStateModel) { return state.loading; }
	@Selector() static saving(state: UsersPermissionsStateModel) { return state.saving; }
	@Selector() static selectedUser(state: UsersPermissionsStateModel) { return state.selectedUser; }
	@Selector() static userLoading(state: UsersPermissionsStateModel) { return state.userLoading; }
	@Selector() static totalUsers(state: UsersPermissionsStateModel) { return state.totalUsers; }
	@Selector() static lastDeletedId(state: UsersPermissionsStateModel) { return state.lastDeletedId; }
	@Selector() static allRoles(state: UsersPermissionsStateModel) { return state.roleOptions; }

	@Action(LoadUsers)
	loadUsers(ctx: StateContext<UsersPermissionsStateModel>, action: LoadUsers) {
		ctx.patchState({
			items: [],
			loading: true,
			totalUsers: 0
		});
		
		return this.usersPermissionsService.getUsers(action.skipCount, action.maxResult, action.filter).pipe(
			tap(
				(response) => {
					ctx.patchState({
						items: response.items,
						loading: false,
						totalUsers: response.totalCount
					});
				}
			)
		);
	}

	@Action(SelectUser)
	selectUser(ctx: StateContext<UsersPermissionsStateModel>, action: SelectUser) {
		ctx.patchState({
			selectedUser: null,
			userLoading: true
		});
		
		return this.usersPermissionsService.getUserWithPermissions(action.id).pipe(
			tap(
				(response: UserWithPermissions) => {
					ctx.patchState({
						selectedUser: response,
						userLoading: false
					});
				}
			)
		);
	}

	@Action(SaveUserPermissions)
	saveUserPermissions(ctx: StateContext<UsersPermissionsStateModel>, action: SaveUserPermissions) {
		ctx.patchState({ saving: true });

		return this.usersPermissionsService.saveUserPermissions(action.id, action.permissions).pipe(
			tap(
				(response: UserWithPermissions) => {
					action.callback(null);
					ctx.patchState({
						selectedUser: response,
						saving: false
					});
				},
				(error) => {
					action.callback(error);
				}
			)
		);
	}

	@Action(SaveRoles)
	saveRoles(ctx: StateContext<UsersPermissionsStateModel>, action: SaveRoles) {
		ctx.patchState({ saving: true });

		return this.usersPermissionsService.saveUserRoles(action.id, action.roles).pipe(
			tap(
				(response) => {
					action.callback(null);
					ctx.patchState({ saving: false });
				},
				(error) => {
					action.callback(error);
				}
			)
		);
	}

	@Action(SaveInformation)
	saveInformation(ctx: StateContext<UsersPermissionsStateModel>, action: SaveInformation) {
		ctx.patchState({ saving: true });
		const state = ctx.getState();
		
		const payload = {
			userId: action.id,
			firstName: action.firstName,
			lastName: action.lastName,
			email: action.email,
			phoneNumber: action.phoneNumber,
			multiFactorEnabled: action.multiFactorEnabled
		};
		return this.usersPermissionsService.updateUser(payload).pipe(
			tap(
				(response) => {
					action.callback(null);
					ctx.patchState({
						items: getUpdatedItems(response, state.items),
						saving: false
					});
				},
				(error) => {
					action.callback(error);
				}
			)
		);
	}

	@Action(CreateUser)
	createUser(ctx: StateContext<UsersPermissionsStateModel>, action: CreateUser) {
		ctx.patchState({ saving: true });

		return this.usersPermissionsService.createUser(action.user).pipe(
			tap(
				(response) => {
					action.callback(false);
					ctx.patchState({ saving: false });
				},
				(error) => {
					action.callback(error);
				}
			)
		);
	}

	@Action(RemoveUser)
	removeUser(ctx: StateContext<UsersPermissionsStateModel>, action: RemoveUser) {
		ctx.patchState({ saving: true });

		return this.usersPermissionsService.deleteUser(action.id).pipe(
			tap(
				(response) => {
					action.callback(null);
					ctx.patchState({
						saving: false,
						lastDeletedId: action.id
					});
				},
				(error) => {
					action.callback(error);
				}
			)
		);
	}

	@Action(LoadRoles)
	loadRoles(ctx: StateContext<UsersPermissionsStateModel>) {
		ctx.patchState({
			items: [],
			loading: true
		});
		
		return this.usersPermissionsService.getRoles().pipe(
			tap(
				(response) => {
					ctx.patchState({
						roleOptions: response.roles.map(
							(item) => {
								return { text: item.name, value: item.name };
							}
						)
					});
				}
			)
    );
	}

	@Action(ResetSelectedUser)
	resetSelectedUser(ctx: StateContext<UsersPermissionsStateModel>) {
		ctx.patchState({
			selectedUser: null
		})
	}
}
