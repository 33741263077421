export class LoadUsers {
  public static readonly type = "[UsersPermissions] load users";
  constructor(
    public skipCount: number, 
    public maxResult: number, 
    public filter: string
  ) {}
}

export class SelectUser {
  public static readonly type = "[UsersPermissions] select user";
  constructor(
    public id: string
  ) {}
}

export class SaveUserPermissions {
  public static readonly type = "[UsersPermissions] save user";
  constructor(
    public id: string, 
    public permissions: any, 
    public callback: (error) => void
  ) {}
}

export class LoadRoles {
  public static readonly type = "[UserPermissions] load roles";
}

export class SaveRoles {
  public static readonly type = "[UsersPermissions] save roles";
  constructor(
    public id: string, 
    public roles: any, 
    public callback: (error) => void
  ) {}
}

export class SaveInformation {
  public static readonly type = "[UsersPermissions] save information";
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phoneNumber: string,
    public multiFactorEnabled: boolean,
    public callback: (error) => void
  ) {}
}


export class CreateUser {
  public static readonly type = "[UsersPermissions] create user";
  constructor(
    public user: any,
    public callback: (error) => void
  ) {}
}

export class RemoveUser {
  public static readonly type = "[UsersPermissions] remove user";
  constructor(
    public id: string,
    public callback: (error) => void
  ) {}
}

export class ResetSelectedUser {
  public static readonly type = "[UsersPermissions] reset selected user";
}