import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { AdminPagesStateModel } from "../../admin.state";
import { UserWithPermissions } from "../models/user.interface";

export class UsersPermissionsStateModel extends AdminPagesStateModel {
	items: Array<UserWithPermissions>;
	selectedUser: UserWithPermissions;
	userLoading: boolean;
	totalUsers: number;
	lastDeletedId: string;
	roleOptions: SelectOption[];
}